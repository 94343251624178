import { Layout, Modal, Teaser } from 'App/components';

import { Grid } from '@mui/material';

import { useState } from 'react';

import { Button, ButtonAppearance } from 'components';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { useTranslation } from 'react-i18next';

import { useCookies } from 'react-cookie';

import { appConfig } from 'App/appConfig/appConfig';

import { EcoActionOnboardingPageStyles } from './EcoActionOnboardingPage.styles';
import { EcoActionOnboardingIllustration } from './assets/EcoActionOnboardingIllustration';

export const EcoActionOnboardingPage = () => {
  const { t } = useTranslation();
  const [, setCookie] = useCookies([appConfig.REACT_APP_ACTION_ONBOARDING_COOKIE_NAME]);

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal
        slug='eco-action-onboarding-what'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={
          <EcoActionOnboardingPageStyles.ModalContentWrapper>
            <div>{t('action:onboarding:what:title')}</div>
            <EcoActionOnboardingPageStyles.ModalContentText
              variant='body2'
              dangerouslySetInnerHTML={{
                __html: t('action:onboarding:what:description') ?? '',
              }}
            />
          </EcoActionOnboardingPageStyles.ModalContentWrapper>
        }
        successButtonText={t('common:close')}
      />

      <Layout bgColor='dark' isFullHeight>
        <EcoActionOnboardingPageStyles.Wrapper>
          <Grid container flexDirection='column' alignItems='center' gap='35px'>
            <div>
              <Teaser title={t('action:onboarding:title') ?? ''} isAlignCenter />
              <EcoActionOnboardingPageStyles.TextWrapper variant='body1'>
                {t('action:onboarding:subtitle')}
              </EcoActionOnboardingPageStyles.TextWrapper>
            </div>
            <EcoActionOnboardingPageStyles.ImgWrapper>
              <EcoActionOnboardingPageStyles.Img>
                <EcoActionOnboardingIllustration />
              </EcoActionOnboardingPageStyles.Img>
            </EcoActionOnboardingPageStyles.ImgWrapper>
            <EcoActionOnboardingPageStyles.BottomTextContainer>
              <EcoActionOnboardingPageStyles.TextWrapper variant='body2'>
                {t('action:onboarding:text')}
              </EcoActionOnboardingPageStyles.TextWrapper>
              <EcoActionOnboardingPageStyles.LinkWrapper variant='body1' onClick={() => setIsModalOpen(true)}>
                {t('action:onboarding:what:title')}
              </EcoActionOnboardingPageStyles.LinkWrapper>
            </EcoActionOnboardingPageStyles.BottomTextContainer>
          </Grid>
          <EcoActionOnboardingPageStyles.ButtonWrapper>
            <Button
              isSmall
              text={t('common:next')}
              appearance={ButtonAppearance.LIGHT}
              onClick={() => {
                setCookie(appConfig.REACT_APP_ACTION_ONBOARDING_COOKIE_NAME, 'true');
                navigate(RoutePaths.ECO_ACTION);
              }}
            />
          </EcoActionOnboardingPageStyles.ButtonWrapper>
        </EcoActionOnboardingPageStyles.Wrapper>
      </Layout>
    </>
  );
};
