import { styled, Typography } from '@mui/material';

const ProjectHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingTop: 24,
  paddingBottom: 24,
  gap: 16,
});

const ImageSlider = styled('div')({
  paddingLeft: 16,
  paddingRight: 16,
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  gap: 16,

  '&::-webkit-scrollbar': { display: 'none' },
});

const Image = styled('img')({
  borderRadius: 8,
  height: 196,
  maxWidth: '100%',
  objectFit: 'cover',
});

const TitleWrapper = styled('div')({
  paddingLeft: 16,
  paddingRight: 16,
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  paddingTop: 16,
  paddingBottom: 16,
  background: theme.palette.primary.light,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const ContinueWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 20,
  paddingBottom: 20,
}));

export const EcoActionProjectPageStyles = {
  ProjectHeader,
  ImageSlider,
  Image,
  TitleWrapper,
  Title,
  ContentWrapper,
  ContinueWrapper,
};
