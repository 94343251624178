import { SVGProps } from 'react';

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.31 15.7586V11.7364C12.31 10.921 13.1926 10.4114 13.8987 10.8191C14.2265 11.0083 14.4283 11.3579 14.4283 11.7364V16.4126C14.4589 18.0436 12.7125 19.0961 11.2847 18.3071C10.5971 17.9272 10.1761 17.198 10.1908 16.4126V11.2783C10.1908 8.83162 12.8394 7.30248 14.9583 8.52579C15.9417 9.09356 16.5475 10.1428 16.5475 11.2783V15.7586'
      stroke='#FF5E00'
      strokeWidth='1.42857'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.57073 12.4777H1.9993C1.6962 12.4777 1.40551 12.3573 1.19118 12.143C0.976853 11.9287 0.856445 11.638 0.856445 11.3349V2.19201C0.856445 1.8889 0.976853 1.59821 1.19118 1.38388C1.40551 1.16956 1.6962 1.04915 1.9993 1.04915H15.7136C16.0167 1.04915 16.3074 1.16956 16.5217 1.38388C16.736 1.59821 16.8564 1.8889 16.8564 2.19201V7.33334'
      stroke='#FF5E00'
      strokeWidth='1.42857'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.5974 1.46666L10.3925 6.24075C9.95198 6.57963 9.41183 6.76337 8.85608 6.76337C8.30033 6.76337 7.76017 6.57963 7.3197 6.24075L1.11475 1.46666'
      stroke='#FF5E00'
      stroke-width='1.42857'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
