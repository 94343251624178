import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';

import { OffsetInfoStyles } from './OffsetInfo.styles';

type OffsetInfoProps = { pricePerTon: number; onAmountChange: (amount: number) => void };

export const OffsetInfo = ({ pricePerTon, onAmountChange }: OffsetInfoProps) => {
  const { t } = useTranslation();

  const [stringAmount, setStringAmount] = useState('');
  const convertInput = (value: string): string => {
    const sanitized = value.replace(/[^0-9.,]/g, '');
    let lastComma = sanitized.lastIndexOf(',');
    let lastDot = sanitized.lastIndexOf('.');
    return lastComma > lastDot ? sanitized.replace(/\./g, '').replace(',', '.') : sanitized.replace(/,/g, '');
  };
  const amount = stringAmount === '' ? 0 : parseFloat(stringAmount);
  const formatNumber = (value: number): string =>
    new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);

  useEffect(() => onAmountChange(amount), [amount, onAmountChange]);

  return (
    <OffsetInfoStyles.Wrapper>
      <OffsetInfoStyles.Title variant='body1'>{t('action:detail:offset:title')}</OffsetInfoStyles.Title>
      <OffsetInfoStyles.Description variant='body2'>
        {t('action:detail:offset:description')}
      </OffsetInfoStyles.Description>
      <OffsetInfoStyles.AmountTextField
        variant='outlined'
        InputProps={{
          endAdornment: (
            <OffsetInfoStyles.AmountTextFieldAdornment position='end'>AED</OffsetInfoStyles.AmountTextFieldAdornment>
          ),
        }}
        value={stringAmount}
        onChange={(e) => setStringAmount(convertInput(e.target.value))}
      />
      <OffsetInfoStyles.Info>
        <OffsetInfoStyles.InfoRow>
          <OffsetInfoStyles.InfoTitle variant='body1'>
            {t('action:detail:offset:offset:title')}
          </OffsetInfoStyles.InfoTitle>
          <OffsetInfoStyles.InfoValue variant='subtitle1'>
            {formatNumber(amount / pricePerTon)} {t('action:detail:offset:offset:unit')}
          </OffsetInfoStyles.InfoValue>
        </OffsetInfoStyles.InfoRow>
        <OffsetInfoStyles.InfoRow>
          <OffsetInfoStyles.InfoTitle variant='body1'>
            {t('action:detail:offset:cost:title')}
          </OffsetInfoStyles.InfoTitle>
          <OffsetInfoStyles.InfoValue variant='subtitle1'>
            {t('action:detail:offset:cost:unit')} {formatNumber(pricePerTon)}
          </OffsetInfoStyles.InfoValue>
        </OffsetInfoStyles.InfoRow>
        <OffsetInfoStyles.InfoRow>
          <OffsetInfoStyles.InfoTitle variant='body1'>
            {t('action:detail:offset:total:title')}
          </OffsetInfoStyles.InfoTitle>
          <OffsetInfoStyles.InfoValue variant='subtitle1'>
            {t('action:detail:offset:total:unit')} {formatNumber(amount)}
          </OffsetInfoStyles.InfoValue>
        </OffsetInfoStyles.InfoRow>
      </OffsetInfoStyles.Info>
    </OffsetInfoStyles.Wrapper>
  );
};
