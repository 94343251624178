import { foodSettings } from '../en';

export const foodSettingsAR: typeof foodSettings = {
  title: 'إعداداتك.',
  subtitle: 'إذا طريقة تسوقك تغيرت ، يمكنك ضبط هذه الإعدادات. بفعل هذا ستساعدنا على حساب بصمتك الكربونية بأكثر دقة.',
  submitButton: 'ارسال',
  feedbackLoopButton: 'تحسين بصمتك',
  submittedTitle: 'لقدأحسنت في إعداداتك',
  submittedSubtitle: `البصمة الكربونية CO₂e لمعاملاتك قد تغيرت.`,
  categoryButton: 'عرض جميع المعاملات',
};
