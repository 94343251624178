import { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ThemeOptionContext, resetStateAction, useUserContext } from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { Dialog } from 'components/Dialog';

import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Button, ButtonAppearance } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { THEME_OPTION } from 'App/types';

import { CharacterTime, CharacterTimeGold, CharacterTimePrivate } from './assets';

import { AutoLogoutStyles } from './AutoLogout.styles';

export const AutoLogout = () => {
  const {
    REACT_APP_AUTO_LOGOUT_TIMER,
    REACT_APP_AUTO_LOGOUT_MODAL_TIMER,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
  } = appConfig;
  const { t } = useTranslation();
  const [, dispatch] = useUserContext();
  const [isVisible, setVisible] = useState(false);
  const [seconds, setSeconds] = useState(REACT_APP_AUTO_LOGOUT_MODAL_TIMER);
  const [isAwake, setAwake] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [cookies, , removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);

  const stayAwake = () => {
    setVisible(false);
    setAwake(true);
    setTimeout(() => setSeconds(REACT_APP_AUTO_LOGOUT_MODAL_TIMER), 1000);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const goBackToHome = () => {
      setVisible(true);
      setAwake(false);
    };

    const restartAutoReset = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        goBackToHome();
      }, REACT_APP_AUTO_LOGOUT_TIMER);
    };

    const onMouseMove = () => {
      restartAutoReset();
    };

    if (location.pathname === RoutePaths.LOGOUT_PAGE) return undefined;

    restartAutoReset();

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [REACT_APP_AUTO_LOGOUT_TIMER, location]);

  useEffect(() => {
    if (seconds > 0 && !isAwake) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (!isAwake) {
      if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]) {
        removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
        removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
      }
      dispatch(resetStateAction());
      setVisible(false);
      setAwake(true);
      setTimeout(() => setSeconds(REACT_APP_AUTO_LOGOUT_MODAL_TIMER), 1000);
      navigate(RoutePaths.LOGOUT_PAGE);
    }
  }, [
    seconds,
    isAwake,
    dispatch,
    REACT_APP_AUTO_LOGOUT_MODAL_TIMER,
    navigate,
    cookies,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
    removeCookie,
  ]);

  const characteTime = {
    [THEME_OPTION.NEO]: <CharacterTime />,
    [THEME_OPTION.GOLD]: <CharacterTimeGold />,
    [THEME_OPTION.PRIVATE]: <CharacterTimePrivate />,
  };

  const { themeOption } = useContext(ThemeOptionContext);

  return (
    <Dialog
      isOpen={isVisible}
      onClose={() => stayAwake()}
      isCenteredText
      imgComponent={characteTime[themeOption as THEME_OPTION]}
      hasExtraPadding
      hasWhiteBackground
      contentHasDarkBackground
      removeCloseIcon
      slug={t('autoLogout:slug')}
    >
      <AutoLogoutStyles.Title variant='h6' sx={{ typography: { sm: 'h6', lg: 'subtitle1' } }}>
        {t('autoLogout:title')}
      </AutoLogoutStyles.Title>
      <AutoLogoutStyles.Subtitle variant='body2'>{t('autoLogout:content')}</AutoLogoutStyles.Subtitle>
      <AutoLogoutStyles.Content variant='subtitle2'>
        {t('autoLogout:counterMessage:prefix')} {seconds} {t('autoLogout:counterMessage:postfix')}
      </AutoLogoutStyles.Content>
      <Grid display='flex' width='100%' justifyContent='center'>
        <Button appearance={ButtonAppearance.LIGHT} onClick={() => stayAwake()} text={t('autoLogout:button')} />
      </Grid>
    </Dialog>
  );
};
