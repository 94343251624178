import { styled, Typography } from '@mui/material';
import { ButtonAppearance } from 'components';

import { OpenProjectIcon } from '../assets/OpenProjectIcon';

const ProjectTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.primary,
}));

const ProjectRecommendedBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: theme.direction === 'rtl' ? 'auto' : 20,
  right: theme.direction === 'rtl' ? 20 : 'auto',
  bottom: 12,
  borderRadius: 6,
  backgroundColor: theme.palette.custom.badge.backgroundColor,
  paddingLeft: 12,
  paddingRight: 12,
  height: 32,
  display: 'flex',
  alignItems: 'center',
}));

const ProjectRecommendedBadgeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.badge.text,
  fontSize: 10,
  fontWeight: 500,
}));

const CardButton = styled('div')({
  marginTop: 8,
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  gap: 8,
});

const CardButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.button[ButtonAppearance.LIGHT].text,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1.7,
}));

const CardButtonIcon = styled(OpenProjectIcon)(({ theme }) => ({
  color: theme.palette.custom.button[ButtonAppearance.LIGHT].text,
  transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'none',
}));

export const ProjectCardStyles = {
  ProjectTitle,
  ProjectRecommendedBadge,
  ProjectRecommendedBadgeText,
  CardButton,
  CardButtonText,
  CardButtonIcon,
};
