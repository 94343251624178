import { styled, Typography } from '@mui/material';

import { EcoActionBannerAsset } from './assets/EcoActionBannerAssset';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  paddingTop: 30,
  // paddingBottom: 30,
  paddingLeft: 24,
  paddingRight: 24,
  background: theme.palette.primary.light,
}));

const Banner = styled('div')(({ theme }) => ({
  background: theme.palette.custom.layout.light,
  borderRadius: 16,
  display: 'flex',
  position: 'relative',
}));

const BannerContent = styled('div')({
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 20,
  paddingRight: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  width: '70%',
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const BannerAsset = styled(EcoActionBannerAsset)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: theme.direction === 'rtl' ? 'auto' : 0,
  left: theme.direction === 'rtl' ? 0 : 'auto',
  transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'none',
}));

export const EcoActionBannerStyles = {
  Wrapper,
  Banner,
  BannerContent,
  Title,
  Subtitle,
  BannerAsset,
};
