import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import {
  common,
  commonAR,
  foodSettings,
  foodSettingsAR,
  homePage,
  homePageAR,
  sso,
  ssoAR,
  footer,
  logout,
  logoutAR,
  reportDetails,
  reportDetailsAR,
  profileSettings,
  profileSettingsAR,
  routes,
  transactionDetails,
  transactionDetailsAR,
  enums,
  enumsAR,
  chart,
  chartAR,
  autoLogout,
  autoLogoutAR,
  poc,
  landingPage,
  landingPageAR,
  action,
  actionAR,
} from 'impactApp/translations';
import { setGlobalLocale } from 'App/utils';

const resources = {
  en: {
    common,
    foodSettings,
    homePage,
    sso,
    footer,
    logout,
    reportDetails,
    profileSettings,
    routes,
    transactionDetails,
    enums,
    chart,
    autoLogout,
    poc,
    landingPage,
    action,
  },
  ar: {
    common: commonAR,
    homePage: homePageAR,
    sso: ssoAR,
    logout: logoutAR,
    reportDetails: reportDetailsAR,
    profileSettings: profileSettingsAR,
    transactionDetails: transactionDetailsAR,
    enums: enumsAR,
    chart: chartAR,
    autoLogout: autoLogoutAR,
    landingPage: landingPageAR,
    foodSettings: foodSettingsAR,
    action: actionAR,
  },
};

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    resources,
    debug: false,
    fallbackLng: 'en',
    saveMissing: true,
  })
  .then(function () {
    setGlobalLocale(i18next.language);
  });
