import { RoutePaths } from 'impactApp/routes/routePaths';

import { NavigationRoutes, NavigationItem } from './types';

export const routesNavigationList: Array<NavigationRoutes | NavigationItem> = [
  {
    name: 'routes:homePage',
    id: 'id_home_page',
    link: RoutePaths.HOME,
  },
  {
    name: 'routes:takeAction',
    id: 'id_take_action_page',
    link: '',
    outlink: true,
  },
  {
    name: 'routes:logoutPage',
    id: 'id_logout_page',
    link: RoutePaths.LOGOUT_PAGE,
  },
];
