import { List as MuiList } from '@mui/material';

import { useAccordion } from './hooks';
import { AccordionAppearance } from './enums';
import { AccordionProps } from './types';

export const Accordion = ({ appearance = AccordionAppearance.LIGHT, itemList }: AccordionProps) => {
  const accordionList = useAccordion(itemList, appearance);

  return <MuiList id='card-list'>{accordionList}</MuiList>;
};
