import { PropsWithChildren } from 'react';

import { useTheme } from 'styled-components/macro';

import { ListItem as MuiListItem, ListItemAvatar } from '@mui/material';

import { HighlightedColor } from 'App/types';

import { ChevronRightIcon, CloudIcon as BasicCloudIcon } from 'components/Icons';

import { Avatar, AvatarSize, Grid } from 'components';

import { ListItemProps } from 'components/List/ListItem';

import { BasicListItemStyles } from './BasicListItem.styles';

export const BasicListItem = ({
  appearance,
  avatarSize = AvatarSize.MEDIUM,
  date: itemDate,
  detailsIcon = <BasicCloudIcon />,
  icon,
  id,
  information: itemInformation,
  title,
  value,
  weightValue,
  isAvatarOrange,
  hideChevron,
}: PropsWithChildren<ListItemProps>) => {
  const {
    palette: {
      custom: {
        list: { highlightedData, iconItemColor },
      },
      grey,
    },
  } = useTheme();
  const date = itemDate ? (
    <BasicListItemStyles.Information variant='caption'>{itemDate}</BasicListItemStyles.Information>
  ) : null;
  const information = itemInformation ? (
    <BasicListItemStyles.Information id='list-item-information' variant='caption'>
      {itemInformation}
    </BasicListItemStyles.Information>
  ) : null;
  const isHightlightedRed = appearance === HighlightedColor.HIGHLIGHTED_RED;
  return (
    <MuiListItem id={id} alignItems='flex-start'>
      <ListItemAvatar>
        <Avatar color={isAvatarOrange ? iconItemColor : 'black'} icon={icon} size={avatarSize} />
      </ListItemAvatar>
      <Grid justifyContent='space-between' width='100%'>
        <Grid flexDirection='column' width='100%'>
          <BasicListItemStyles.Title
            id='list-item-title'
            sx={{ typography: { sm: isHightlightedRed ? 'subtitle3' : 'subtitle2', lg: 'subtitle2' } }}
          >
            {title}
          </BasicListItemStyles.Title>
          {information}
          {date}
        </Grid>
        <Grid justifyContent='flex-end' alignItems='center' width='100%' columnGap={{ sm: 0.5 }}>
          <Grid alignItems='flex-end' flexDirection='column'>
            <Grid alignItems='center'>
              <BasicListItemStyles.DetailsIcon $color={highlightedData[appearance]}>
                {detailsIcon}
              </BasicListItemStyles.DetailsIcon>
              <BasicListItemStyles.Value
                variant='caption'
                sx={{
                  typography: { sm: isHightlightedRed ? 'caption' : 'subtitle3', lg: 'caption' },
                }}
                $colorText
                appearance={appearance}
              >
                {weightValue}
              </BasicListItemStyles.Value>
            </Grid>
            <BasicListItemStyles.Value variant='caption' appearance={appearance}>
              {value}
            </BasicListItemStyles.Value>
          </Grid>
          {!hideChevron && (
            <BasicListItemStyles.IconWrapper>
              <ChevronRightIcon color={grey['200']} />
            </BasicListItemStyles.IconWrapper>
          )}
        </Grid>
      </Grid>
    </MuiListItem>
  );
};
