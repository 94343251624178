export const common = {
  tryAgain: 'try again',
  noData: 'Sorry, there is \nno data available.',
  logoAlt: 'Mashreq',
  seeMore: 'See more',
  shrink: 'Less',
  cancel: 'Cancel',
  hour: 'h',
  collapse: 'Collapse',
  expand: 'Expand',
  carbonDioxide: 'CO<sub>2</sub>e',
  inPreposition: 'in',
  fromPreposition: 'from',
  link: 'How does the calculation work?',
  seoncdaryLink: 'How does the calculation work?',
  headerInfoText: 'Your transactions have been analyzed and your carbon footprint calculated.',
  closeProfileSettingsModal: {
    content:
      'Answering these 2 short questions will help us calculate a more accurate personal carbon footprint for you. Do you want to answer these questions to make your footprint more accurate? <br><br> Click ‘Yes’ to answer these questions. Click ‘No’ to skip them',
    ja: 'Yes',
    nein: 'No',
    slug: 'close-profile-settings-modal',
  },
  ok: 'Ok',
  next: 'Next',
  continue: 'Continue',
  close: 'Close',
  accept: 'Accept',
  reject: 'Reject',
  merchantPlaceholder: 'Your Payment',
  cookies: {
    title: 'Your cookie settings.',
    text: `Cookies are helpful small data packages that make it easier for us to offer you an optimal and personalized user experience. We use cookies on this site to improve your experience and for analytics purposes. Select the cookies you want below.`,
    cookiesObject: {
      allowNecessary: {
        title: 'Strictly necessary',
        modalText: `
        Strictly necessary cookies help us provide the services this website needs to function. For example, when you log in, we use cookies to make sure it's you and that you don't have to log in every time you navigate to a new page. Accepting strictly necessary cookies is mandatory to use this Cards Carbon Footprint.`,
      },
      allowAnalytics: {
        title: 'Analytic cookies',
        modalText: `
        Analytic cookies are cookies that give us statistical information on how you use the website, such as the features you use and how long you stay on the site. We use this information to improve the website for the future. We also share this information with our vendor, ecolytiq, to improve their products and services.`,
      },
    },
    acceptAll: 'Select all and accept',
    acceptSelection: 'Accept selection',
  },
  tc: {
    title: 'Terms & Conditions.',
    content: `<b>Do you want to use the Cards Carbon Footprint? </b> <br /> <br />
      If you choose to use the Cards Carbon Footprint, we share information about your credit 
      card transactions with our vendor, ecolytiq GmbH (<b>“ecolytiq”</b>), to calculate a 
      carbon dioxide equivalent (CO₂e) value based on your transactions. The information 
      is de-identified before it is shared, and our vendor is only permitted to use the 
      information to provide the calculations of the CO₂e value.`,
    contentExtended: `<span>
    <b>Please note that calculations are estimated and not actual measures of 
    your carbon footprint.</b> The calculation is a rough estimate based on shopping 
    categories (i.e. food, transportation, shopping, living and uncategorized), and the average 
    carbon emissions for that category based on publicly available data, studies and 
    research. We do not know, and do not take into account, the specific products or 
    services that you have purchased. <br /><br/>
    That's why, for some transactions, we'll ask you questions about your shopping 
    decisions and habits. <b>These questions are optional</b>; you are not required to 
    answer any of these questions in order to use the Cards Carbon Footprint.  If you choose to 
    respond to these questions, our vendor ecolytiq will use the information to provide 
    more accurate information about your carbon footprint.
    </span>`,
    accept: 'Use the Cards Carbon Footprint',
    reject: 'Don’t use the Cards Carbon Footprint',
    slug: 'TANDC',
  },
};
