import { useTheme } from 'styled-components/macro';

import { AccordionAppearance } from '../enums';

export const useMapAppearanceToColor = (appearance: AccordionAppearance) => {
  const {
    palette: {
      common: { white, black },
      custom: {
        layout: { dark },
      },
    },
  } = useTheme();

  const colorsMap = {
    [AccordionAppearance.LIGHT]: { iconColor: white, textColor: white },
    [AccordionAppearance.DARK]: { iconColor: dark, textColor: black },
  };

  return colorsMap[appearance];
};
