import { styled } from '@mui/material';

const Overlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
});

const ContentWrapper = styled('div')({
  paddingBottom: 16,
  flexGrow: 1,
  overflowY: 'auto',
});

const Content = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 24,
  paddingBottom: 24,

  '& *': { marginBottom: 16 },
}));

const ButtonWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 20,
  paddingBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

export const TermsOverlayStyles = {
  Overlay,
  ContentWrapper,
  Content,
  ButtonWrapper,
};
