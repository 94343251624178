import { motion } from 'framer-motion';
import { subMonths } from 'date-fns';

import { useUserContext } from 'App/context';
import { useListActions } from 'App/hooks';
import { animationList } from 'App/style/animations';
import { HighlightedColor, Categories } from 'App/types';
import { convertGramsToLargerUnits, formatFullDate, pickDataFromRange, formatNumber, formatCurrency } from 'App/utils';
import { CurrencySymbol } from 'App/translations';
import { useTranslation } from 'react-i18next';

import { List, ListItem, ListStyles } from 'components';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { renderIcon } from 'impactApp/modules/ReportDetailsPage/utils';
import { Co2Footprint, Transaction } from '@ecolytiq/js-data-provider';
import { CURRENT_DATE } from 'App/components';
import i18next from 'i18next';

import { t as translation } from 'i18next';

import { useCO2MonthlyData } from '../../useCO2MonthlyData';

import { TransactionListHeader } from './TransactionListHeader';

import { useTransactionsListData } from './useTransactionsListData';

const INITIAL_ITEMS_TO_DISPLAY = 3;

type ReportDetailsTransactionListProps = {
  category: Categories;
  currentTotalCo2ByMonth: Co2Footprint | {};
};

const generateItems = (transactions: Transaction[], locale: string) =>
  transactions.map((transaction: Transaction) => {
    const {
      date,
      merchant,
      co2Footprint: { value: co2Value },
      id: transactionId,
      amount: { value, currencyCode },
      metaCategory,
    } = transaction;

    const { ORANGE } = HighlightedColor;
    const formatDate = formatFullDate(date, locale);
    const formatCO2ToKg = convertGramsToLargerUnits(co2Value);
    const redirectUrl = `${RoutePaths.TRANSACTIONS}/${transactionId}`;
    const amountValue = formatCurrency(value, currencyCode as CurrencySymbol);
    const footprint = `${formatNumber(+formatCO2ToKg[0])} ${i18next.t(formatCO2ToKg[1])}`;

    return (
      <motion.div key={transactionId} animate='visible' initial='hidden' variants={animationList}>
        <ListItem
          appearance={ORANGE}
          icon={renderIcon(metaCategory as Categories)}
          id={transactionId}
          information={formatDate}
          redirectURL={redirectUrl}
          title={merchant?.name || translation('common:merchantPlaceholder')}
          value={amountValue}
          weightValue={footprint}
          transaction={transaction}
          isAvatarOrange
        />
        <ListStyles.Divider />
      </motion.div>
    );
  });

export const ReportDetailsTransactionList = ({
  category,
  currentTotalCo2ByMonth,
}: ReportDetailsTransactionListProps) => {
  const { i18n, t } = useTranslation();
  const [{ monthDelta }] = useUserContext();
  const currentDate = subMonths(CURRENT_DATE, monthDelta);
  const lastMonth = subMonths(CURRENT_DATE, monthDelta + 1);

  const { isLoading, transactions } = useTransactionsListData(
    {
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    },
    category
  );

  const { totalCo2ByMonth } = useCO2MonthlyData(
    {
      month: lastMonth.getMonth() + 1,
      year: lastMonth.getFullYear(),
    },
    category
  );
  const isBigger = (totalCo2ByMonth as any)?.value < (currentTotalCo2ByMonth as any)?.value;
  const ratio = isBigger
    ? (currentTotalCo2ByMonth as any)?.value / (totalCo2ByMonth as any)?.value
    : (currentTotalCo2ByMonth as any)?.value / (totalCo2ByMonth as any)?.value;

  const footprintDelta = ratio === 1 ? 0 : +(isBigger ? ratio * 100 - 100 : -100 + ratio * 100).toFixed(2);
  const itemsToExpand = 5;

  const {
    handleShowMoreItems,
    handleResetItemsToShow,
    canFetchMore,
    isExpanderVisible,
    handleOnSwipeLeft,
    handleOnSwipeRight,
    handleOnMonthChange,
    amountOfListItems,
  } = useListActions<Transaction>(INITIAL_ITEMS_TO_DISPLAY, itemsToExpand, transactions);
  const ListHeader = (
    <TransactionListHeader
      category={category}
      footprintDelta={
        (totalCo2ByMonth as any)?.value && (currentTotalCo2ByMonth as any)?.value ? footprintDelta : false
      }
      month={monthDelta}
      onMonthChange={handleOnMonthChange}
    />
  );

  return (
    <List
      canFetchMore={canFetchMore}
      expandList={handleShowMoreItems}
      isExpanderVisible={isExpanderVisible}
      isLoading={isLoading}
      listHeader={ListHeader}
      listItems={generateItems(pickDataFromRange<Transaction>(transactions, amountOfListItems), i18n.language)}
      noDataText={t('common:noData')}
      shortenList={handleResetItemsToShow}
      swipeLeftCallback={handleOnSwipeLeft}
      swipeRightCallback={handleOnSwipeRight}
      paddingTop
    />
  );
};
