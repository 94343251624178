import { useTranslation } from 'react-i18next';

import enImg01 from '../assets/development-goals/en/E_WEB_01.png';
import enImg02 from '../assets/development-goals/en/E_WEB_02.png';
import enImg03 from '../assets/development-goals/en/E_WEB_03.png';
import enImg04 from '../assets/development-goals/en/E_WEB_04.png';
import enImg05 from '../assets/development-goals/en/E_WEB_05.png';
import enImg06 from '../assets/development-goals/en/E_WEB_06.png';
import enImg07 from '../assets/development-goals/en/E_WEB_07.png';
import enImg08 from '../assets/development-goals/en/E_WEB_08.png';
import enImg09 from '../assets/development-goals/en/E_WEB_09.png';
import enImg10 from '../assets/development-goals/en/E_WEB_10.png';
import enImg11 from '../assets/development-goals/en/E_WEB_11.png';
import enImg12 from '../assets/development-goals/en/E_WEB_12.png';
import enImg13 from '../assets/development-goals/en/E_WEB_13.png';
import enImg14 from '../assets/development-goals/en/E_WEB_14.png';
import enImg15 from '../assets/development-goals/en/E_WEB_15.png';
import enImg16 from '../assets/development-goals/en/E_WEB_16.png';
import enImg17 from '../assets/development-goals/en/E_WEB_17.png';

import arImg01 from '../assets/development-goals/ar/A_WEB_01.png';
import arImg02 from '../assets/development-goals/ar/A_WEB_02.png';
import arImg03 from '../assets/development-goals/ar/A_WEB_03.png';
import arImg04 from '../assets/development-goals/ar/A_WEB_04.png';
import arImg05 from '../assets/development-goals/ar/A_WEB_05.png';
import arImg06 from '../assets/development-goals/ar/A_WEB_06.png';
import arImg07 from '../assets/development-goals/ar/A_WEB_07.png';
import arImg08 from '../assets/development-goals/ar/A_WEB_08.png';
import arImg09 from '../assets/development-goals/ar/A_WEB_09.png';
import arImg10 from '../assets/development-goals/ar/A_WEB_10.png';
import arImg11 from '../assets/development-goals/ar/A_WEB_11.png';
import arImg12 from '../assets/development-goals/ar/A_WEB_12.png';
import arImg13 from '../assets/development-goals/ar/A_WEB_13.png';
import arImg14 from '../assets/development-goals/ar/A_WEB_14.png';
import arImg15 from '../assets/development-goals/ar/A_WEB_15.png';
import arImg16 from '../assets/development-goals/ar/A_WEB_16.png';
import arImg17 from '../assets/development-goals/ar/A_WEB_17.png';

import { SustainabilityGoalStyles } from './SustainabilityGoal.styles';

// eslint-disable-next-line prettier/prettier
export const GOAL_IDS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'] as const;
export type GoalId = (typeof GOAL_IDS)[number];
type SustainabilityGoalProps = { goalId: GoalId; description: string };

// eslint-disable-next-line prettier/prettier
const EN_GOAL_IMAGE: Record<GoalId, string> = { 1: enImg01, 2: enImg02, 3: enImg03, 4: enImg04, 5: enImg05, 6: enImg06, 7: enImg07, 8: enImg08, 9: enImg09, 10: enImg10, 11: enImg11, 12: enImg12, 13: enImg13, 14: enImg14, 15: enImg15, 16: enImg16, 17: enImg17 };
// eslint-disable-next-line prettier/prettier
const AR_GOAL_IMAGE: Record<GoalId, string> = { 1: arImg01, 2: arImg02, 3: arImg03, 4: arImg04, 5: arImg05, 6: arImg06, 7: arImg07, 8: arImg08, 9: arImg09, 10: arImg10, 11: arImg11, 12: arImg12, 13: arImg13, 14: arImg14, 15: arImg15, 16: arImg16, 17: arImg17 };

export const SustainabilityGoal = ({ goalId: goal, description }: SustainabilityGoalProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <SustainabilityGoalStyles.Wrapper>
      {language == 'ar' && <SustainabilityGoalStyles.Image src={AR_GOAL_IMAGE[goal]} />}
      {language != 'ar' && <SustainabilityGoalStyles.Image src={EN_GOAL_IMAGE[goal]} />}
      <SustainabilityGoalStyles.TextWrapper>
        <SustainabilityGoalStyles.TextContent variant='body2'>{description}</SustainabilityGoalStyles.TextContent>
      </SustainabilityGoalStyles.TextWrapper>
    </SustainabilityGoalStyles.Wrapper>
  );
};
