import { SVGProps } from 'react';

export const OpenProjectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 11.75H21.5M21.5 11.75L17.75 15.5M21.5 11.75L17.75 8'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
