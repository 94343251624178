import { BASIC_GRADIENT_BACKGROUND_HEIGHT, Layout, Modal } from 'App/components';
import { GradientHeader, Header, InnerContainer } from 'components';

import { Container } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useState } from 'react';

import { useCookies } from 'react-cookie';

import { appConfig } from 'App/appConfig/appConfig';

import { generatePath, Navigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { useCarbonCreditProjects, useTotalStatistics } from 'impactApp/api';

import { formatNumber } from 'App/utils';

import { EcoActionOnboardingPageStyles } from '../EcoActionOnboardingPage/EcoActionOnboardingPage.styles';

import { EcoActionDashboardPageStyles } from './EcoActionDashboardPage.styles';
import { ProjectCard } from './submodules/ProjectCard';

import { RecentTransactionsList } from './submodules/RecentTransactionsList';
import { InfoIcon } from './assets/InfoIcon';

const findTranslation = <T extends { language_code: string }>(translations: T[], lang: string): T | undefined => {
  return translations.find((translation) => translation.language_code === lang);
};

export const EcoActionDashboardPage = () => {
  const { t, i18n } = useTranslation();
  const [cookies] = useCookies([appConfig.REACT_APP_ACTION_ONBOARDING_COOKIE_NAME]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: statisticsData } = useTotalStatistics({});
  const { data: projectsData } = useCarbonCreditProjects();

  if (!cookies[appConfig.REACT_APP_ACTION_ONBOARDING_COOKIE_NAME])
    return <Navigate to={generatePath(RoutePaths.ECO_ACTION_ONBOARDING)} />;

  return (
    <>
      <Modal
        slug='eco-action-onboarding-what'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={
          <EcoActionOnboardingPageStyles.ModalContentWrapper>
            <div>{t('action:onboarding:what:title')}</div>
            <EcoActionOnboardingPageStyles.ModalContentText
              variant='body2'
              dangerouslySetInnerHTML={{
                __html: t('action:onboarding:what:description') ?? '',
              }}
            />
          </EcoActionOnboardingPageStyles.ModalContentWrapper>
        }
        successButtonText={t('common:close')}
      />

      <Layout
        bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT}
        bgColor='dark'
        hideContainer
        header={
          <Header
            title={t('action:dashboard:header:title') ?? ''}
            transparent
            rightButton={
              <EcoActionDashboardPageStyles.InfoButton onClick={() => setIsModalOpen(true)}>
                <InfoIcon />
              </EcoActionDashboardPageStyles.InfoButton>
            }
            showDesktopNavigation
          />
        }
      >
        <GradientHeader>
          <EcoActionDashboardPageStyles.HeaderContentWrapper>
            <EcoActionDashboardPageStyles.HeaderContentTitle>
              {t('action:dashboard:header:carbonCredits')}
            </EcoActionDashboardPageStyles.HeaderContentTitle>
            <EcoActionDashboardPageStyles.HeaderContentSubtitle>
              {t('action:dashboard:header:timeFrame')}
            </EcoActionDashboardPageStyles.HeaderContentSubtitle>
            <EcoActionDashboardPageStyles.HeaderContentKpi>
              {formatNumber(Math.round((statisticsData?.intervals.find(() => true)?.co2_credits_in_g ?? 0) / 1000))}{' '}
              {t('action:dashboard:header:unit')}
            </EcoActionDashboardPageStyles.HeaderContentKpi>
          </EcoActionDashboardPageStyles.HeaderContentWrapper>
        </GradientHeader>

        <Container>
          <InnerContainer>
            <EcoActionDashboardPageStyles.ProjectListWrapper>
              <EcoActionDashboardPageStyles.ProjectListTitle>
                {t('action:dashboard:projectList:title')}
              </EcoActionDashboardPageStyles.ProjectListTitle>
              <EcoActionDashboardPageStyles.ProjectListSubtitle>
                {t('action:dashboard:projectList:subtitle')}
              </EcoActionDashboardPageStyles.ProjectListSubtitle>

              {projectsData?.projects.map((project) => {
                const title =
                  findTranslation(project.translations, i18n.language)?.title ||
                  findTranslation(project.translations, 'en')?.title;

                return (
                  <ProjectCard
                    key={project.id}
                    title={title ?? ''}
                    image={project.images.find(() => true)?.image_url}
                    projectId={project.id}
                  />
                );
              })}
            </EcoActionDashboardPageStyles.ProjectListWrapper>
          </InnerContainer>
        </Container>

        <EcoActionDashboardPageStyles.RecentTransactionsWrapper>
          <RecentTransactionsList />
        </EcoActionDashboardPageStyles.RecentTransactionsWrapper>
      </Layout>
    </>
  );
};
