import { common } from '../en';

export const commonAR: typeof common = {
  tryAgain: 'حاول مرة أخرى',
  noData: 'عذرا ، لا توجد بيانات متاحة.',
  logoAlt: 'مشرق',
  seeMore: 'عرض المزيد',
  shrink: 'عرض أقل',
  cancel: 'إلغاء',
  hour: 'س',
  collapse: 'اخفاء',
  expand: 'عرض المزيد',
  carbonDioxide: 'CO<sub>2</sub>e',
  inPreposition: 'في',
  fromPreposition: 'من',
  link: 'ما هي بصمتك الكربونية  و كيف نقوم بحسابها ؟',
  seoncdaryLink: 'ما هي بصمتك الكربونية  و كيف نقوم بحسابها ؟',
  headerInfoText: 'تم تحليل معاملاتك كما تم حساب بصمتك الكربونية.',
  closeProfileSettingsModal: {
    content:
      "بالإجابة على السؤالين القصيرين، ستساعدنا في احتساب بصمتك الكربونية بأكثر دقة. هل ترغب في الإجابة على هذين السؤالين لجعل بصمتك دقيقة أكثر؟ <br/><br/> انقر على 'نعم' للإجابة على هذين السؤالين. انقر على 'لا' لتجاوزهما.",
    ja: 'تعم',
    nein: 'لا',
    slug: 'close-profile-settings-modal',
  },
  ok: 'وافق',
  next: 'التالي',
  close: 'الإغلاق',
  continue: 'الاستمرار',
  accept: 'الموافقة',
  reject: 'الرفض',
  merchantPlaceholder: 'الدفع الخاص بك',
  cookies: {
    title: 'إعدادات ملفات تعريف الارتباط الخاصة بك. ',
    text: `ملفات تعريف الارتباط عبارة عن حزم بيانات صغيرة مفيدة تسهل علينا أن نقدم لك تجربة مستخدم مثالية ومخصصة. نحن نستخدم ملفات تعريف الارتباط على هذا الموقع لتحسين تجربتك ولأغراض التحليل والإعلان. اختر ملفات تعريف الارتباط التي تريدها أدناه.`,
    cookiesObject: {
      allowNecessary: {
        title: 'ضروري للغاية',
        modalText: `تساعدنا ملفات تعريف الارتباط الضرورية للغاية في تقديم الخدمات التي يحتاجها هذا الموقع للعمل. على سبيل المثال، عند تسجيل الدخول، نستخدم ملفات تعريف الارتباط للتأكد من هويتك وأنه ليس عليك تسجيل الدخول في كل مرة تنتقل فيها إلى صفحة جديدة. يعد قبول ملفات تعريف الارتباط الضرورية للغاية أمرًا إلزاميًا لاستخدام عداد الكربون هذا.
        `,
      },
      allowAnalytics: {
        title: 'ملفات تعريف الارتباط التحليلية',
        modalText: `
        ملفات تعريف الارتباط التحليلية هي ملفات تعريف ارتباط تزودنا بمعلومات إحصائية حول كيفية استخدامك للموقع ، مثل الخصائص التي تستخدمها ومدة بقائك على الموقع. ونحن نستخدم هذه المعلومات لتحسين الموقع  لمستقبل.  نشارك أيضا هذه المعلومات مع بائعنا ، ecolytiq ، لتحسين منتجاتهم وخدماتهم.  
        `,
      },
    },
    acceptAll: 'قم بتحديد الكل واقبله',
    acceptSelection: 'اختيار قبول',
  },
  tc: {
    title: 'الشّروط و الأحكام',
    content: `إذا اخترت استخدام Cards Carbon Footprint، فإننا نشارك معلومات حول معاملات بطاقة الائتمان الخاصة بك مع بائعنا، شركة   ecolytiq GmbH ‏(“ecolytiq”)، لحساب قيمة مكافئ ثاني أكسيد الكربون (CO2e) بناءً على معاملاتك. يتم إلغاء تحديد المعلومات قبل مشاركتها،  ولا يُسمح لبائعنا إلا باستخدام المعلومات لتوفير حسابات قيمة مكافئ ثاني أكسيد الكربون CO2e.`,
    contentExtended: `<span>
    يرجى ملاحظة أن الحسابات تقديرية وليست مقاييس فعلية لبصمتك الكربونية . الحساب عبارة عن تقدير تقريبي يعتمد على فئات التسوق (أي الطعام والنقل والتسوق والمعيشة وغير المصنف) ، ومتوسط انبعاثات الكربون لتلك الفئة بناء على البيانات المتاحة للجمهور الدراسات والبحوث. لا نعلم, ولا تأخذ في الاعتبار المنتجات أو الخدمات المحددة التي اشتريتها. لهذا السبب ، بالنسبة لبعض المعاملات ، سنطرح عليك أسئلة حول قرارات وعادات التسوق الخاصة بك. هذه الأسئلة اختيارية، ولست مطالبًا بالإجابة على أي من هذه الأسئلة من أجل استخدام عداد الكربون. إذا اخترت الرد على هذه الأسئلة ، فسيستخدم بائعنا ecolytiq المعلومات لتوفير معلومات أكثر دقة حول بصمتك الكربونية.
    </span>`,
    accept: 'قبول',
    reject: 'رفض',
    slug: 'TANDC',
  },
};
