import { SVGProps } from 'react';

export const CO2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3162_303)'>
      <g clipPath='url(#clip1_3162_303)'>
        <path
          d='M1.14282 11.8571C1.14282 14.6987 2.27165 17.424 4.28096 19.4333C6.29028 21.4426 9.0155 22.5714 11.8571 22.5714C14.6987 22.5714 17.4239 21.4426 19.4333 19.4333C21.4426 17.424 22.5714 14.6987 22.5714 11.8571C22.5714 9.01553 21.4426 6.29031 19.4333 4.28099C17.4239 2.27168 14.6987 1.14285 11.8571 1.14285C9.0155 1.14285 6.29028 2.27168 4.28096 4.28099C2.27165 6.29031 1.14282 9.01553 1.14282 11.8571Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.99997 8.28571C8.2422 8.28571 7.51548 8.58672 6.97966 9.12254C6.44384 9.65836 6.14282 10.3851 6.14282 11.1428V12.5714C6.14282 13.3292 6.44384 14.0559 6.97966 14.5917C7.51548 15.1275 8.2422 15.4286 8.99997 15.4286'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5714 8.28571C12.1925 8.28571 11.8292 8.43622 11.5612 8.70412C11.2933 8.97203 11.1428 9.3354 11.1428 9.71428V14C11.1428 14.3789 11.2933 14.7422 11.5612 15.0101C11.8292 15.2781 12.1925 15.4286 12.5714 15.4286C12.9503 15.4286 13.3136 15.2781 13.5815 15.0101C13.8495 14.7422 14 14.3789 14 14V9.71428C14 9.3354 13.8495 8.97203 13.5815 8.70412C13.3136 8.43622 12.9503 8.28571 12.5714 8.28571Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.1428 13.6428C16.1428 13.3587 16.2557 13.0862 16.4566 12.8852C16.6576 12.6843 16.9301 12.5714 17.2143 12.5714C17.4984 12.5714 17.7709 12.6843 17.9719 12.8852C18.1728 13.0862 18.2857 13.3587 18.2857 13.6428C18.2851 13.989 18.1672 14.3247 17.9514 14.5952L16.1428 16.8571H18.2857'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3162_303'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
      <clipPath id='clip1_3162_303'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
