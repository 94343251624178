import { RoutePaths } from 'impactApp/routes/routePaths';
import { EditIcon } from 'components';

import { FoodSettingsButtonStyles } from './FoodSettingsButton.styles';

export const FoodSettingsButton = () => {
  const { FOOD_SETTINGS } = RoutePaths;

  return (
    <FoodSettingsButtonStyles.InfoButton to={{ pathname: FOOD_SETTINGS }}>
      <EditIcon />
    </FoodSettingsButtonStyles.InfoButton>
  );
};
