import { styled, Typography } from '@mui/material';

import { CheckFillIcon } from 'components/Icons/CheckFillIcon';

import { EmailIcon as EmailIconSVG } from 'components/Icons/EmailIcon';

import { EcoActionCheckoutSuccessIllustration } from './assets/EcoActionCheckoutSuccessIllustration';

const Wrapper = styled('div')({
  height: '100%',
  minHeight: '100vh',
  padding: 50,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  gap: 24,
});

const Icon = styled(CheckFillIcon)({
  height: 40,
  width: 40,
});

const Title = styled(Typography)({});
const Subtitle = styled(Typography)({});

const Illustration = styled(EcoActionCheckoutSuccessIllustration)({
  width: '100%',
  height: 'auto',
});

const InfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const EmailTextWrapper = styled('div')({
  display: 'flex',
  gap: 16,
});

const EmailText = styled(Typography)({});
const EmailIcon = styled(EmailIconSVG)({});

const ButtonWrapper = styled('div')({
  marginTop: 40,
  display: 'flex',
  justifyContent: 'center',
});

export const EcoActionCheckoutSuccessPageStyles = {
  Wrapper,
  Icon,
  Title,
  Subtitle,
  Illustration,
  InfoText,
  EmailTextWrapper,
  EmailText,
  EmailIcon,
  ButtonWrapper,
};
