import { HighlightedColor, Categories, CategoriesTranslation } from 'App/types';
import { convertGramsToLargerUnits, formatNumber } from 'App/utils';
import { ListStyles, ListItem, ProgressBar } from 'components';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { renderIcon } from 'impactApp/modules/ReportDetailsPage/utils';
import { useTranslation } from 'react-i18next';

type FootprintListItemProps = {
  name: string;
  co2: number;
  co2Ratio: number;
  progressBarColor: string;
};

export const FootprintListItem = ({ name, co2, co2Ratio, progressBarColor }: FootprintListItemProps) => {
  const { ORANGE } = HighlightedColor;
  const { t } = useTranslation();
  const convertedCo2 = convertGramsToLargerUnits(co2);
  const footprintValue = `${formatNumber(+convertedCo2[0])} ${t(convertedCo2[1])}`;
  const co2Value = `${formatNumber(+co2Ratio.toFixed(2))}%`;
  const redirectUrl = `${RoutePaths.REPORTS}/${name.toLowerCase()}`;
  const translatedTitle = t(CategoriesTranslation[name as keyof typeof CategoriesTranslation]);
  return (
    <>
      <ListItem
        appearance={ORANGE}
        icon={renderIcon(name as Categories)}
        information={<ProgressBar progressBarColor={progressBarColor} value={co2Ratio} />}
        redirectURL={redirectUrl}
        title={translatedTitle}
        value={co2Value}
        weightValue={footprintValue}
        isAvatarOrange
      />
      <ListStyles.Divider />
    </>
  );
};
