import styled from 'styled-components/macro';

import { Typography } from '@mui/material';

const Content = styled(Typography)`
  margin-bottom: 32px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 52px;
  }
`;

const Title = styled(Typography)`
  margin-bottom: 16px;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 20px;
`;

export const AutoLogoutStyles = {
  Title,
  Subtitle,
  Content,
};
