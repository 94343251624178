import { Layout } from 'App/components';

import { Button } from 'components';

import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePaths } from 'impactApp/routes/routePaths';

import { useTranslation } from 'react-i18next';

import { EcoActionCheckoutSuccessPageStyles } from './EcoActionCheckoutSuccessPage.styles';

export const EcoActionCheckoutSuccessPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Layout bgColor='dark' isFullHeight>
      <EcoActionCheckoutSuccessPageStyles.Wrapper>
        <EcoActionCheckoutSuccessPageStyles.Icon />
        <EcoActionCheckoutSuccessPageStyles.Title variant='h6'>
          {t('action:checkout:success:title')}
        </EcoActionCheckoutSuccessPageStyles.Title>
        <EcoActionCheckoutSuccessPageStyles.Illustration />
        <EcoActionCheckoutSuccessPageStyles.Subtitle variant='body1'>
          {t('action:checkout:success:text')}
        </EcoActionCheckoutSuccessPageStyles.Subtitle>
        <EcoActionCheckoutSuccessPageStyles.EmailTextWrapper>
          <EcoActionCheckoutSuccessPageStyles.EmailIcon />
          <EcoActionCheckoutSuccessPageStyles.EmailText variant='body2'>
            {t('action:checkout:success:email')}
          </EcoActionCheckoutSuccessPageStyles.EmailText>
        </EcoActionCheckoutSuccessPageStyles.EmailTextWrapper>
        <EcoActionCheckoutSuccessPageStyles.ButtonWrapper>
          <Button
            text={t('action:checkout:success:buttonText')}
            onClick={() => navigate(generatePath(RoutePaths.HOME))}
          />
        </EcoActionCheckoutSuccessPageStyles.ButtonWrapper>
      </EcoActionCheckoutSuccessPageStyles.Wrapper>
    </Layout>
  );
};
