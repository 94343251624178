import { homePage } from '../en';

export const homePageAR: typeof homePage = {
  appName: 'Cards Carbon Footprint',
  intro: {
    subtitle: 'بصمتك الكربونية (ما يصل إلى 6 شهرا)',
    comparison: {
      compareTo: 'من المتوسط ​​السنوي لدولة الإمارات العربية المتحدة',
      outcome: {
        above: 'اكثر',
        below: 'اقل',
      },
    },
  },
  howItWorks: {
    title: 'ما هي بصمتك الكربونية و كيف نقوم بحسابها ؟',
    slug: 'how-it-works',
    description: [
      `ثاني أكسيد الكربون هو السبب الرئيسي لتغير المناخ، لذلك من المهم تقليله حيثما أمكن ذلك.. قياس بصمتك الكربونية خطوة أولى رائعة. , من خلال تحليل بيانات إنفاقاتك يمكنك تحديد كمية ثاني أكسيد الكربون التي تنتجها أفعالك بشكل تقريبي - من إختياراتك لالطعام والتسوق إلى وسائل النقل.`,
      `وهنا يأتي دور شريكنا، ecolytiq: من خلال عداد الكربون الخاص بنا، سيقومون بتعيين معاملاتك إلى فئة تسوق معينة (أي الطعام أو التنقل أو التسوق أو المعيشة أو العناصر غير المصنفة) وقيمة مكافئ ثاني أكسيد الكربون CO2e بناءً على البيانات والدراسات والأبحاث المتاحة. ضع في اعتبارك أن هذه الحسابات تقديريّة ولا تأخذ في الاعتبار المنتجات المحددة التي تشتريها. لهذا السبب، لبعض المعاملات، سوف نطرح عليك أسئلة قررات التسوق و عاداتك. هذه الأسئلة اختيارية، لكن الإجابة عليها ستمنحك فكرة أفضل عن بصمتك الكربونية وكيف يمكنك تقليلها.`,
    ],
    list: [
      {
        title: 'غازات الدفيئة',
        body: '<p>الغازات الدفيئة (أو اختصارًا GHG) هو أي غاز يحبس الحرارة في الغلاف الجوي وبالتالي يساهم في ظاهرة الاحتباس الحراري العالمي. الغازات الرئيسية التي تساهم في الاحتباس الحراري العالمي هي ثاني أكسيد الكربون (CO₂) والميثان (CH4) وأكسيد النيتروز (N2O) والغازات المفلورة.</p>',
      },
      {
        title: 'ثاني أكسيد الكربون (CO₂)',
        body: '<p>غاز الدفيئة (أو اختصارًا GHG) هو أي غاز يحبس الحرارة في الغلاف الجوي وبالتالي يساهم في ظاهرة الاحتباس الحراري العالمي. الغازات الرئيسية التي تساهم في الاحتباس الحراري العالمي هي ثاني أكسيد الكربون (CO₂) والميثان (CH4) وأكسيد النيتروز (N2O) والغازات المفلورة.</p>',
      },
      {
        title: 'مكافئ ثاني أكسيد الكربون (CO₂e)',
        body: `<p>مكافئ ثاني أكسيد الكربون هو مقياس أنشأته الأمم المتحدة من أجل قياس تأثير الاحترار الكلي لجميع غازات الدفيئة في رقم واحد. وغازات الدفيئة الأخرى التي لها تأثير احتراري مشابهة لثاني أكسيد الكربون. على سبيل المثال، الميثان له تأثير احتراري 84 ضعفًا للتأثير الاحتراري للثاني أكسيد الكربون ولكنه لا يدوم في الغلاف الجوي لفترة طويلة، لذا فإن 1 كجم من الميثان له نفس تأثير 25 كجم من ثاني أكسيد الكربون تقريبًا، ويتم التعبير عنه بـ 25 كجم من مكافئ ثاني أكسيد الكربون.</p>`,
      },
      {
        title: 'البصمة الكربونية',
        body: '<p>البصمة الكربونية هي قياس كمية ثاني أكسيد الكربون التي تنتجها أفعالك. وتقاس البصمة بوحدة CO₂e وبأي من المقاييس المترية جرام (g) أو كيلوجرام (kg) أو طن (t). 1000 جم يساوي 1 كجم. 1000 كجم يساوي 1 طن.</p>',
      },
    ],
  },
  cta: {
    title:
      'Informieren Sie sich jetzt zum Thema Nachhaltigkeit sowie den nachhaltigen Geldanlagen unseres Partners Union Investment.',
    button: 'Jetzt informieren',
  },
  footprintReport: {
    listTitle: 'CO<sub>2</sub>e إجمالي مكافئ ثان أكسيد الكربون',
    listSubtitle: 'في الشهر',
  },
  transactionFootprint: {
    title: 'أكبر العناصر المصدرة للانبعاثات ',
  },
};
