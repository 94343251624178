import { styled, Typography } from '@mui/material';

const InfoButton = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 8,
  color: theme.palette.common.white,
}));

const HeaderContentWrapper = styled('div')({
  padding: '40px 24px 64px',
});

const HeaderContentText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const HeaderContentTitle = styled(HeaderContentText)({
  fontSize: 14,
  weight: 500,
});

const HeaderContentSubtitle = styled(HeaderContentText)({
  fontSize: 12,
});

const HeaderContentKpi = styled(HeaderContentText)({
  fontSize: 32,
  fontWeight: 700,
  marginTop: 32,
});

const Ellipsis = styled(Typography)({
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ProjectListWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  paddingTop: 32,
  paddingX: 24,
  paddingBottom: 16,
});

const ProjectListTitle = styled(Ellipsis)(({ theme }) => ({
  marginBottom: 4,
  fontSize: 18,
  fontWeight: 700,
  color: theme.palette.common.black,
}));

const ProjectListSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.secondary,
}));

const RecentTransactionsWrapper = styled('div')(({ theme }) => ({
  paddingTop: 16,
  paddingBottom: 16,
  background: theme.palette.primary.light,
}));

export const EcoActionDashboardPageStyles = {
  InfoButton,
  HeaderContentWrapper,
  HeaderContentTitle,
  HeaderContentSubtitle,
  HeaderContentKpi,
  ProjectListWrapper,
  ProjectListTitle,
  ProjectListSubtitle,
  RecentTransactionsWrapper,
};
