import { Typography } from '@mui/material';

import { Grid } from 'components';

import { HeaderWithValue, Comparison } from 'App/components';

import { convertGramsToLargerUnits } from 'App/utils/convertGramsToLargerUnits';

import { ComparisonValue } from 'App/types';

import { useTranslation } from 'react-i18next';

import { HeaderStyles } from './Header.styles';

type HeaderProps = {
  averageFootprint: number;
  value: number;
};

export const Header = ({ averageFootprint, value }: HeaderProps) => {
  const comparisonDifference = convertGramsToLargerUnits(Math.abs(averageFootprint - value));
  const footprint = convertGramsToLargerUnits(value);

  const { t, i18n } = useTranslation();
  console.log(i18n.dir);

  const comparison =
    averageFootprint > value
      ? t('homePage:intro:comparison:outcome:below')
      : t('homePage:intro:comparison:outcome:above');
  const { INCREASE, DECREASE } = ComparisonValue;
  const comparisonArrow = averageFootprint > value ? DECREASE : INCREASE;

  const comparisonMessage = `${comparison} ${t('homePage:intro:comparison:compareTo')}`;
  return (
    <>
      <Grid flexDirection='column'>
        <HeaderStyles.Wrapper>
          <HeaderStyles.Title>
            <Typography color='common.white' variant='subtitle2'>
              {t('homePage:intro:subtitle')}
            </Typography>
          </HeaderStyles.Title>
          <HeaderStyles.ArrowWrapper>
            <HeaderWithValue isExtraLarge isLarge unitVariant='h2' value={footprint} color='common.white' />
          </HeaderStyles.ArrowWrapper>
          <Comparison trend={comparisonArrow} comparison={comparisonDifference} comparisonMessage={comparisonMessage} />
        </HeaderStyles.Wrapper>
      </Grid>
    </>
  );
};
