import { useState, useMemo, useCallback } from 'react';

import { handleOpenedOverlay } from 'App/utils';

import { color } from 'App/style';

import { useLocation } from 'react-router-dom';

import { ModalProps } from './types';

import { ModalStyles } from './Modal.styles';

export const useModal = () => {
  const [isOpen, setVisibility] = useState(false);
  const openCallback = useCallback(() => {
    setVisibility(true);
  }, []);
  const closeCallback = useCallback(() => {
    setVisibility(false);
  }, []);

  const { pathname } = useLocation();

  const Modal = useMemo(
    () =>
      ({ content, render, slug }: ModalProps) => {
        if (isOpen) {
          handleOpenedOverlay(slug, pathname);
        }
        return (
          <ModalStyles.Dialog onClose={closeCallback} open={isOpen} scroll='paper'>
            {render({
              closeButton: <ModalStyles.CloseButton hoverColor={color.lightRed} onClick={closeCallback} />,
              content: <ModalStyles.Content id='dialog-content'>{content}</ModalStyles.Content>,
            })}
          </ModalStyles.Dialog>
        );
      },
    [closeCallback, isOpen, pathname]
  );

  return {
    Modal,
    openCallback,
    closeCallback,
  };
};
