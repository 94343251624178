import { compose } from 'ramda';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRoutes } from 'react-router-dom';
import { requests, useQueryErrorResetBoundary } from '@ecolytiq/js-data-provider';

import { useDeps } from 'App/context';
import { HttpErrorFallback } from 'App/components';
import { ROUTES } from 'impactApp/routes';

const MockedApi = (children: JSX.Element) => {
  const { workerService } = useDeps();

  useEffect(() => {
    workerService.start();
    workerService.addHandlers([...requests]);
  }, [workerService]);

  return children;
};

type AppErrorBoundariesProps = { children: React.ReactNode };

export const AppErrorBoundaries = ({ children }: AppErrorBoundariesProps) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary FallbackComponent={HttpErrorFallback} onReset={reset}>
      {children}
    </ErrorBoundary>
  );
};

export const App = () => <AppErrorBoundaries>{useRoutes(ROUTES)}</AppErrorBoundaries>;

export const AppWithMockedApi = () => compose(MockedApi)(<App />);
