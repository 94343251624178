import { autoLogout } from '../en';

export const autoLogoutAR: typeof autoLogout = {
  title: 'هل ما زلت هنا؟',
  content: 'إذا لم تتفاعل مع عداد الكربون لمدة 5 دقائق، فسيتم تسجيل خروجك تلقائيًا.',
  button: 'ابق مسجلا',
  slug: 'TIMEOUT',
  counterMessage: {
    prefix: 'تسجيل الخروج في',
    postfix: 'ثوان',
  },
};
