import styled, { css } from 'styled-components/macro';

import { ButtonAppearance } from 'components';

export const BasicButton = css`
  transition: ${({ theme }) => `${theme.transition('background')}`};
  min-width: 200px;
  height: 56px;
  border-radius: ${({ theme }) => theme.palette.custom.radius.radiusSmall};
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  max-width: 100%;
  cursor: pointer;
  text-decoration: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  white-space: nowrap;
`;

export const ButtonColors = (appearance: ButtonAppearance) => css`
  ${appearance === ButtonAppearance.SECONDARY
    ? css`
        background-color: transparent;
        color: ${({ theme }) => theme.palette.custom.button[appearance].backgroundColor};
        border: 2px solid ${({ theme }) => theme.palette.custom.button[appearance].backgroundColor};
        border-radius: ${({ theme }) => theme.palette.custom.radius.radiusSmall};
      `
    : css`
        color: ${({ theme }) => theme.palette.custom.button[appearance].text};
        background-color: ${({ theme }) => theme.palette.custom.button[appearance].backgroundColor};
      `}
  :hover {
    ${appearance === ButtonAppearance.SECONDARY
      ? css`
          color: ${({ theme }) => theme.palette.custom.button[appearance].hoverBackground};
          border-color: ${({ theme }) => theme.palette.custom.button[appearance].hoverBackground};
          svg path {
            stroke: ${({ theme }) => theme.palette.custom.button[appearance].hoverBackground};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.palette.custom.button[appearance].hoverBackground};
        `}
  }
  :active {
    ${appearance === ButtonAppearance.SECONDARY
      ? css`
          color: ${({ theme }) => theme.palette.custom.button[appearance].activeBackground};
          border-color: ${({ theme }) => theme.palette.custom.button[appearance].activeBackground};
          svg path {
            stroke: ${({ theme }) => theme.palette.custom.button[appearance].activeBackground};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.palette.custom.button[appearance].activeBackground};
        `}
  }
  :disabled {
    ${appearance === ButtonAppearance.SECONDARY
      ? css`
          color: ${({ theme }) => theme.palette.custom.button[appearance].disabledBackground};
          border-color: ${({ theme }) => theme.palette.custom.button[appearance].disabledBackground};
        `
      : css`
          background-color: ${({ theme }) => theme.palette.custom.button[appearance].disabledBackground};
        `}
  }
  svg path {
    stroke: ${({ theme }) => theme.palette.custom.button[appearance].text};
  }
`;

export const ButtonText = css`
  font-size: ${({ theme }) => `${theme.typography.subtitle2.fontSize}`};
  line-height: ${({ theme }) => `${theme.typography.subtitle2.lineHeight}`};
  font-weight: 500;
`;

const Button = styled('button')<{
  $isSmall?: boolean;
  $isLarge?: boolean;
  $isFullWidth?: boolean;
  appearance: ButtonAppearance;
  isTransparent?: boolean;
}>`
  ${BasicButton}
  ${ButtonText}
  ${({ appearance }) => ButtonColors(appearance)};

  svg:first-child {
    margin-right: 4px;
  }

  svg:last-child {
    margin-left: 4px;
  }

  ${({ isTransparent }) =>
    isTransparent &&
    css`
      background: transparent;
      border-color: transparent;
      color: white;
      svg path {
        stroke: white;
      }
      &:hover,
      &:active {
        background: transparent;
        border-color: transparent;
        color: white;
        svg path {
          stroke: white;
        }
      }
    `};

  ${({ $isSmall, appearance }) =>
    $isSmall &&
    css`
      min-width: 0;
      width: 190px;
      height: 48px;
      font-size: ${({ theme }) => `${theme.typography.subtitle3.fontSize}`};
      line-height: ${({ theme }) => `${theme.typography.subtitle3.lineHeight}`};
      ${appearance === ButtonAppearance.LIGHT && 'border-width: 1px;'}
    `};

  ${({ $isLarge }) =>
    $isLarge &&
    css`
      min-width: 297px;
    `};

  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `};
`;

export const ButtonStyles = {
  Button,
};
