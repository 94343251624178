import { Button, ButtonAppearance } from 'components';

import { useTranslation } from 'react-i18next';

import { generatePath, useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { EcoActionBannerStyles } from './EcoActionBanner.styles';

export const EcoActionBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <EcoActionBannerStyles.Wrapper>
      <EcoActionBannerStyles.Banner>
        <EcoActionBannerStyles.BannerContent>
          <EcoActionBannerStyles.Title variant='subtitle2'>{t('action:banner:title')}</EcoActionBannerStyles.Title>
          <EcoActionBannerStyles.Subtitle variant='body2'>{t('action:banner:subtitle')}</EcoActionBannerStyles.Subtitle>
          <Button
            isSmall
            appearance={ButtonAppearance.LIGHT}
            isFullWidth={false}
            text={t('action:banner:buttonText')}
            onClick={() => navigate(generatePath(RoutePaths.ECO_ACTION))}
          />
        </EcoActionBannerStyles.BannerContent>
        <EcoActionBannerStyles.BannerAsset />
      </EcoActionBannerStyles.Banner>
    </EcoActionBannerStyles.Wrapper>
  );
};
