import { styled, Typography } from '@mui/material';

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
});

const Image = styled('img')({
  width: 60,
  height: 60,
  borderRadius: 2,
});

const TextWrapper = styled('div')({});
const TextContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const SustainabilityGoalStyles = {
  Wrapper,
  Image,
  TextWrapper,
  TextContent,
};
