import { enums } from '../en';

export const enumsAR: typeof enums = {
  unitOfMass: {
    kg: 'كغ',
    t: 'طن',
  },
  months: {
    Jan: 'يناير',
    Feb: 'فبراير',
    Mar: 'مارس',
    Apr: 'أبريل',
    May: 'مايو',
    Jun: 'يونيو',
    Jul: 'يوليو',
    Aug: 'اغسطس',
    Sep: 'سبتمبر',
    Oct: 'اكتوبر',
    Nov: 'نوفمبر',
    Dec: 'ديسمبر',
  },
  categoriesTranslation: {
    food: 'الطعام',
    transportation: 'التنقل',
    shopping: 'التسوق',
    living: 'المعيشة',
    uncategorized: 'عناصر غير مصنفة',
  },
};
