export enum RoutePaths {
  HOME = '/dashboard',
  REPORTS = '/overview',
  REPORT_DETAILS = '/overview/:category',
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/:id',
  TRANSACTION_DETAILS_EDIT = '/transactions/:id/edit',
  PROFILE_SETTINGS = '/profile',
  FOOD_SETTINGS = '/settings',
  LOGOUT_PAGE = '/logout',
  SSO_PAGE = '/sso',
  LOADING_PAGE = '/',
  LANDING_PAGE = '/home',
  ECO_ACTION = '/action',
  ECO_ACTION_ONBOARDING = '/action/onboarding',
  ECO_ACTION_PROJECT = '/action/project/:id',
  ECO_ACTION_CHECKOUT = '/action/checkout',
  ECO_ACTION_CHECKOUT_SUCCESS = '/action/success',
  ECO_ACTION_CHECKOUT_ERROR = '/action/error',
  ECO_ACTION_BANNER = '/action/banner',
}
