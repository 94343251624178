import { Accordion, AccordionAppearance, Item } from 'components';

import { useTranslation } from 'react-i18next';

import { ProjectInfoStyles } from './ProjectInfo.styles';
import { GOAL_IDS, GoalId, SustainabilityGoal } from './SustainabilityGoal';

type ProjectInfoProps = {
  description: string;
  sustainabilityGoals: { goalId: string; description: string }[];
};

export const ProjectInfo = ({ description, sustainabilityGoals }: ProjectInfoProps) => {
  const { t } = useTranslation();

  const items: Item[] = [
    {
      title: t('action:detail:info:detailsTitle'),
      body: description,
    },
    {
      title: t('action:detail:info:vendorTitle'),
      body: 'Cloverly, Inc.',
    },
    {
      title: t('action:detail:info:goalsTitle'),
      body: (
        <ProjectInfoStyles.GoalList>
          {sustainabilityGoals
            .filter((goal): goal is { goalId: GoalId; description: string } => GOAL_IDS.includes(goal.goalId as GoalId))
            .map((goal) => (
              <SustainabilityGoal key={goal.goalId} goalId={goal.goalId} description={goal.description} />
            ))}
        </ProjectInfoStyles.GoalList>
      ),
    },
  ];

  return (
    <ProjectInfoStyles.Wrapper>
      <Accordion itemList={items} appearance={AccordionAppearance.DARK}></Accordion>
    </ProjectInfoStyles.Wrapper>
  );
};
