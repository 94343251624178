import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433292 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.997 7.34876 18.9424 4.80698 17.0677 2.93227C15.193 1.05756 12.6512 0.0030248 10 0V0ZM11.0714 15C11.0714 15.2842 10.9585 15.5567 10.7576 15.7576C10.5567 15.9585 10.2842 16.0714 10 16.0714C9.71584 16.0714 9.44332 15.9585 9.24239 15.7576C9.04146 15.5567 8.92857 15.2842 8.92857 15V10C8.92857 9.71584 9.04146 9.44332 9.24239 9.24238C9.44332 9.04145 9.71584 8.92857 10 8.92857C10.2842 8.92857 10.5567 9.04145 10.7576 9.24238C10.9585 9.44332 11.0714 9.71584 11.0714 10V15ZM10 7.14286C9.71746 7.14286 9.44126 7.05907 9.20633 6.9021C8.9714 6.74512 8.7883 6.52201 8.68017 6.26097C8.57205 5.99994 8.54376 5.7127 8.59888 5.43558C8.654 5.15847 8.79006 4.90392 8.98985 4.70413C9.18964 4.50434 9.44419 4.36828 9.7213 4.31316C9.99842 4.25804 10.2857 4.28633 10.5467 4.39446C10.8077 4.50258 11.0308 4.68569 11.1878 4.92061C11.3448 5.15554 11.4286 5.43174 11.4286 5.71428C11.4286 6.09316 11.2781 6.45653 11.0102 6.72444C10.7422 6.99235 10.3789 7.14286 10 7.14286Z'
      fill='white'
    />
  </svg>
);
