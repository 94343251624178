import { Button, ButtonAppearance, GradientHeader, Header } from 'components';

import { useTranslation } from 'react-i18next';

import { TermsOverlayStyles } from './TermsOverlay.styles';

type TermsOverlayProps = {
  onAccept: () => void;
  onReject: () => void;
};

export const TermsOverlay = ({ onAccept, onReject }: TermsOverlayProps) => {
  const { t } = useTranslation();

  return (
    <TermsOverlayStyles.Overlay>
      <GradientHeader>
        <Header title={t('action:terms:title') ?? ''} transparent isFixed />
        &nbsp;
      </GradientHeader>
      <TermsOverlayStyles.ContentWrapper>
        <TermsOverlayStyles.Content
          dangerouslySetInnerHTML={{
            __html: t('action:terms:text') ?? '',
          }}
        />
      </TermsOverlayStyles.ContentWrapper>
      <TermsOverlayStyles.ButtonWrapper>
        <Button
          isFullWidth
          text={t('common:reject')}
          appearance={ButtonAppearance.SECONDARY}
          onClick={() => onReject()}
        />
        <Button
          isFullWidth
          text={t('common:accept')}
          appearance={ButtonAppearance.PRIMARY}
          onClick={() => onAccept()}
        />
      </TermsOverlayStyles.ButtonWrapper>
    </TermsOverlayStyles.Overlay>
  );
};
