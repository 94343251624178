import { SVGProps } from 'react';

export const CheckFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 19.9984C0 25.3027 2.10714 30.3898 5.85786 34.1405C9.60859 37.8912 14.6957 39.9984 20 39.9984C25.3043 39.9984 30.3914 37.8912 34.1421 34.1405C37.8929 30.3898 40 25.3027 40 19.9984C40 14.694 37.8929 9.60694 34.1421 5.85622C30.3914 2.10549 25.3043 -0.00164795 20 -0.00164795C14.6957 -0.00164795 9.60859 2.10549 5.85786 5.85622C2.10714 9.60694 0 14.694 0 19.9984Z'
      fill='#0DA931'
    />
    <path
      d='M9.3335 22.1744L13.6891 28.3557C13.8543 28.6025 14.076 28.8063 14.3359 28.95C14.5958 29.0938 14.8862 29.1734 15.183 29.1823C15.4799 29.1912 15.7746 29.129 16.0426 29.001C16.3105 28.873 16.5441 28.6829 16.7237 28.4464L30.6668 10.8055'
      stroke='white'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
