import { Card, CardContent, CardMedia } from '@mui/material';

import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePaths } from 'impactApp/routes/routePaths';

import { useTranslation } from 'react-i18next';

import { ProjectCardStyles } from './ProjectCard.styles';

type ProjectCardProps = {
  title: string;
  image: string | undefined;
  projectId: string;
  isRecommended?: boolean;
};

export const ProjectCard = ({ title, image, projectId, isRecommended }: ProjectCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card
      variant='outlined'
      style={{ borderRadius: 12 }}
      onClick={() => navigate(generatePath(RoutePaths.ECO_ACTION_PROJECT, { id: projectId }))}
    >
      <CardMedia style={{ position: 'relative' }} sx={{ height: 120 }} image={image} title={title}>
        {isRecommended && (
          <ProjectCardStyles.ProjectRecommendedBadge>
            <ProjectCardStyles.ProjectRecommendedBadgeText>
              {t('action:dashboard:projectList:isRecommended')}
            </ProjectCardStyles.ProjectRecommendedBadgeText>
          </ProjectCardStyles.ProjectRecommendedBadge>
        )}
      </CardMedia>
      <CardContent>
        <ProjectCardStyles.ProjectTitle>{title}</ProjectCardStyles.ProjectTitle>
        <ProjectCardStyles.CardButton>
          <ProjectCardStyles.CardButtonText>{t('action:dashboard:projectList:more')}</ProjectCardStyles.CardButtonText>
          <ProjectCardStyles.CardButtonIcon />
        </ProjectCardStyles.CardButton>
      </CardContent>
    </Card>
  );
};
