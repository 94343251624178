import { useEffect, useState } from 'react';
import {
  useGetReportingsFootprints,
  useQueryData,
  GetFootprintsResponse,
  DEFAULT_OVERRIDE_CACHE_TIME,
} from '@ecolytiq/js-data-provider';
import { INIT_MONTH_DELTA } from 'App/context/user/User.reducer';

import { useDeps } from 'App/context';
import { subMonths } from 'date-fns';
import { Months } from 'App/types';
import { getCurrentMonth } from 'App/utils';
import { FootprintChartData } from 'impactApp/modules/HomePage';

import { CURRENT_DATE, MONTHS_TO_DISPLAY, CANADIAN_AVERAGE } from 'App/components';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const parseData = ({ totalCo2ByRange }: GetFootprintsResponse): Omit<FootprintChartData, 'isLoading'> => {
  const averageFootprint = CANADIAN_AVERAGE;

  let calculatedAnnualFootprint = 0;

  let numberOfMonths = 0;

  let parsedFootprints = Object.values(totalCo2ByRange).map((footprintsByMonth, index: number) => {
    const {
      co2Footprint: { value },
    } = footprintsByMonth;
    calculatedAnnualFootprint += value;
    if (value > 0) numberOfMonths++;
    return {
      date: i18next.t(`${Months[getCurrentMonth(6 - index, CURRENT_DATE)]}`),
      value,
      monthDelta: 5 - index + INIT_MONTH_DELTA,
    };
  });

  parsedFootprints = parsedFootprints.reverse();

  return {
    annualFootprint: calculatedAnnualFootprint,
    averageFootprint: (averageFootprint / 6) * numberOfMonths,
    footprints: [parsedFootprints.slice(0, MONTHS_TO_DISPLAY), parsedFootprints.slice(MONTHS_TO_DISPLAY)],
  };
};

export const useFootprintChartData = (): FootprintChartData => {
  const { httpClientService } = useDeps();
  const [chartData, setChartData] = useState<Omit<FootprintChartData, 'isLoading'>>({
    annualFootprint: 0,
    averageFootprint: 0,
    footprints: [[], []],
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { SSO_PAGE } = RoutePaths;
  const { annualFootprint, averageFootprint, footprints } = chartData;
  const date = subMonths(CURRENT_DATE, INIT_MONTH_DELTA);

  let currentDateMinusSixMonths = new Date(date);

  currentDateMinusSixMonths.setMonth(currentDateMinusSixMonths.getMonth() - 5);

  if (currentDateMinusSixMonths > new Date(date)) {
    currentDateMinusSixMonths.setFullYear(currentDateMinusSixMonths.getFullYear() - 1);
  }

  const { isLoading, data } = useQueryData<{ data: GetFootprintsResponse }>(
    useGetReportingsFootprints(
      httpClientService,
      {
        month: currentDateMinusSixMonths.getMonth() + 1,
        year: currentDateMinusSixMonths.getFullYear(),
      },
      {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      }
    ),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data?.data) {
      setChartData(parseData(data.data));
    } else if (!isLoading && !data) {
      navigate(SSO_PAGE);
    }
  }, [data?.data, SSO_PAGE, navigate, isLoading, data, t]);

  return {
    annualFootprint,
    averageFootprint,
    footprints,
    isLoading,
  };
};
