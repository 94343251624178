import { useState } from 'react';
import { Grid, Container } from '@mui/material';

import { Layout, Teaser, Link as LinkComponent } from 'App/components';

import { useTranslation } from 'react-i18next';

import { color } from 'App/style';

import { Header, GoBackButton, Button, useModal, GradientHeader } from 'components';

import { CheckIcon, WomanWithGroceries } from 'components/Icons';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { HowItWorksModal } from '../HomePage';

import { QuestionsStyles, Questions } from '../TransactionDetailsEdit/submodules/Questions';

import { FoodSettings } from './FoodSettings.style';

import { useCurrentAccountData } from './useCurrentAccountData';

export const FoodSettingsPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const { Modal, openCallback: openModal } = useModal();
  const { t }: any = useTranslation();
  const { onboardingAnswers } = useCurrentAccountData();
  return (
    <Layout
      bgColor='light'
      hideContainer
      header={<Header transparent showDesktopNavigation hideRightButton button={<GoBackButton />} />}
    >
      <HowItWorksModal Modal={Modal} />
      <GradientHeader>
        <Teaser hasFixedHeader isAlignCenter title={t('foodSettings:title')} subtitle={t('foodSettings:subtitle')} />
      </GradientHeader>
      <FoodSettings.PaperWrapper>
        <Container maxWidth='xl' disableGutters>
          <Grid flexDirection='column' justifyContent='center' alignItems='center'>
            <Grid item>
              <FoodSettings.QuestionsWrapper>
                {isSubmitted ? (
                  <Grid justifyContent='flex-start' alignItems='flex-start'>
                    <QuestionsStyles.Content>
                      <QuestionsStyles.SubmitWrapper>
                        <QuestionsStyles.SubmittedIconWrapper>
                          <CheckIcon />
                        </QuestionsStyles.SubmittedIconWrapper>
                        <QuestionsStyles.SubmittedTitle color='black' variant='h6'>
                          {t('foodSettings:submittedTitle')}
                        </QuestionsStyles.SubmittedTitle>
                        <QuestionsStyles.SubmittedSubtitle color='black' variant='subtitle2'>
                          {t('foodSettings:submittedSubtitle')}
                        </QuestionsStyles.SubmittedSubtitle>
                        <WomanWithGroceries />
                        <FoodSettings.ButtonWrapper>
                          <Button
                            iconRight
                            text={t('foodSettings:categoryButton')}
                            onClick={() => navigate(`${RoutePaths.REPORTS}/food`)}
                          />
                        </FoodSettings.ButtonWrapper>
                      </QuestionsStyles.SubmitWrapper>
                    </QuestionsStyles.Content>
                  </Grid>
                ) : (
                  <>
                    <QuestionsStyles.Content>
                      <Questions
                        submitButtonText={t('foodSettings:submitButton')}
                        saveChanges=''
                        subCategoryValue='1'
                        transactionAnswers={onboardingAnswers}
                        setIsSubmitted={setIsSubmitted}
                        isTransactionLoop={false}
                        category='PROFILE'
                      />
                      <QuestionsStyles.LinkWrapper>
                        <LinkComponent
                          text={t('common:seoncdaryLink')}
                          textColor={color.black}
                          onClick={() => openModal()}
                        />
                      </QuestionsStyles.LinkWrapper>
                    </QuestionsStyles.Content>
                  </>
                )}
              </FoodSettings.QuestionsWrapper>
            </Grid>
          </Grid>
        </Container>
      </FoodSettings.PaperWrapper>
    </Layout>
  );
};
