import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
}));

const GoalList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export const ProjectInfoStyles = {
  Wrapper,
  GoalList,
};
