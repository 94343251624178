import { Config } from 'App/config';

import { usePostSession, useMutateData, getCurrentAccount, PostSessionResponse } from '@ecolytiq/js-data-provider';

import { useNavigate } from 'react-router-dom';

import { useCallback, useEffect } from 'react';

import {
  setSessionTokenAction,
  useUserContext,
  setIsLoggedInAction,
  setIsFirstLoginAction,
  useDeps,
} from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';
import { appConfig } from 'App/appConfig/appConfig';
import { useTranslation } from 'react-i18next';

import { handleUserLogin } from '../../../App/utils';

export const useSessionData = (code: string, state: string, codeVerifier: string) => {
  const { httpClientService, httpClientServiceOAuth } = useDeps();
  const [{ sessionToken }, dispatch] = useUserContext();

  const { i18n } = useTranslation();

  const {
    REACT_APP_DEMO_API,
    REACT_APP_MOCK_API,
    REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_ID,
    REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_SECRET,
  } = Config.getAll();

  const navigate = useNavigate();

  const { REACT_APP_FIRST_LOGIN_COOKIE_NAME, REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } =
    appConfig;

  const [cookies, setCookie] = useCookies([REACT_APP_FIRST_LOGIN_COOKIE_NAME]);

  const loginAndRedirectToHomePage = useCallback(() => {
    dispatch(setIsLoggedInAction(true));
    navigate(RoutePaths.LANDING_PAGE);
  }, [dispatch, navigate]);

  const { mutate: sessionMutate } = useMutateData<PostSessionResponse>(
    usePostSession(httpClientServiceOAuth, code, codeVerifier),
    {
      onSuccess: async (sessionDataResponse: any) => {
        const { access_token, refresh_token } = sessionDataResponse;
        dispatch(setSessionTokenAction(access_token));
        handleUserLogin(access_token);
        httpClientService.removeInterceptor(0);
        const interceptor = httpClientService.setInterceptor(({ headers, ...restRequestConfig }: any) => {
          return {
            ...restRequestConfig,
            headers: {
              ...headers,
              Authorization: `Bearer ${access_token}`,
              'accept-language': i18n.language,
            },
          };
        });

        setCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, {
          path: '/',
        });

        setCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, refresh_token, { path: '/' });

        const firstLoginFunction = getCurrentAccount(httpClientService);
        const account = await firstLoginFunction.get();
        const { onboardingAnswers } = account.data.currentAccount;
        dispatch(setIsLoggedInAction(true));
        const answeredOnboardingAnswers = onboardingAnswers.filter((question: any) => question.answerId !== null);

        if (cookies[REACT_APP_FIRST_LOGIN_COOKIE_NAME] === 'true' || answeredOnboardingAnswers.length > 0) {
          navigate(RoutePaths.HOME);
        } else {
          dispatch(setIsFirstLoginAction(true));
          setCookie(REACT_APP_FIRST_LOGIN_COOKIE_NAME, 'true');
          navigate(RoutePaths.PROFILE_SETTINGS);
        }

        httpClientService.removeInterceptor(interceptor);
      },
      onError: async () => {
        navigate(RoutePaths.SSO_PAGE);
      },
    }
  );

  useEffect(() => {
    if (REACT_APP_MOCK_API === 'true') loginAndRedirectToHomePage();
    else if (REACT_APP_DEMO_API === 'true' && !code) navigate(RoutePaths.LANDING_PAGE);
    else if (!sessionToken && code) {
      httpClientServiceOAuth.removeInterceptor(0);
      httpClientServiceOAuth.setInterceptor(({ headers, ...restRequestConfig }: any) => {
        return {
          ...restRequestConfig,
          headers: {
            ...headers,
            authorization: `Basic ${Buffer.from(
              `${REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_ID}:${REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_SECRET}`
            ).toString('base64')}`,
          },
        };
      });
      sessionMutate();
    }
  }, [
    sessionMutate,
    sessionToken,
    code,
    navigate,
    state,
    REACT_APP_DEMO_API,
    REACT_APP_MOCK_API,
    loginAndRedirectToHomePage,
    REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_ID,
    REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_SECRET,
    httpClientService,
    httpClientServiceOAuth,
  ]);
};
