import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { theme as appTheme } from 'App/style';

type Theme = typeof appTheme;

const Wrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  height: '100%',
  minHeight: '100vh',
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  background: theme.palette.custom.layout.light,
}));

const TextWrapper = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const LinkWrapper = styled(TextWrapper)({
  cursor: 'pointer',
});

const ImgWrapper = styled('div')({
  maxWidth: 400,
  paddingTop: 12,
});

const Img = styled('div')({
  maxWidth: '100%',
});

const BottomTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 80,
});

const ModalContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
});

const ModalContentText = styled(Typography)({});

export const EcoActionOnboardingPageStyles = {
  Wrapper,
  TextWrapper,
  LinkWrapper,
  ImgWrapper,
  Img,
  BottomTextContainer,
  ButtonWrapper,
  ModalContentWrapper,
  ModalContentText,
};
