import { Grid, Container, useMediaQuery, Typography } from '@mui/material';
import { theme } from 'App/style';
import { SubCategoriesImages, SubCategories, CategoriesTranslation } from 'App/types';

import {
  Header as LayoutHeader,
  CircularLoading,
  GoBackButton,
  Button,
  NavLink,
  ComparableText,
  MerchantWrapper,
  GradientHeader,
} from 'components';

import { HeaderWithValue, Layout, BARS_HEIGHT } from 'App/components';

import {
  convertGramsToLargerUnits,
  formatCurrency,
  formatFullDate,
  useRandomComparablesByIdData,
  useRandomInsightsByIdData,
} from 'App/utils';

import { CurrencySymbol } from 'App/translations';

import { useLocation } from 'react-router-dom';

import { Transaction } from '@ecolytiq/js-data-provider';

import { useTranslation } from 'react-i18next';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { DidYouKnow } from '../ReportDetailsPage/submodules';

import { EcoActionBanner } from '../EcoActionBanner/EcoActionBanner';

import { TransactionDetails } from './TransactionDetails.style';

export const TransactionDetailsPage = () => {
  const location = useLocation();

  const transaction: Transaction = location?.state?.transaction;

  const { i18n, t }: any = useTranslation();

  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const { randomInsight } = useRandomInsightsByIdData(transaction?.id);

  const { randomComparable, isLoading } = useRandomComparablesByIdData(transaction?.id);

  const insight: any = randomInsight;

  const Insight = insight?.title ? (
    <DidYouKnow
      isLoading={isLoading}
      category={SubCategoriesImages[transaction?.category.value as keyof typeof SubCategoriesImages]}
      color={theme.palette.common.black}
      content={insight?.text}
      title={insight?.title}
    />
  ) : (
    ''
  );

  if (!transaction) {
    return (
      <Layout bgColor='light' header={<LayoutHeader button={<GoBackButton />} />}>
        <TransactionDetails.Wrapper alignItems='center' height={`calc(100vh - ${BARS_HEIGHT})`}>
          <CircularLoading />
        </TransactionDetails.Wrapper>
      </Layout>
    );
  }

  const {
    id,
    date,
    merchant,
    metaCategory,
    co2Footprint: { value: co2Value },
    amount: { value, currencyCode },
    category: { value: subCategoryValue },
  } = transaction;
  const footprint = convertGramsToLargerUnits(co2Value);

  const feedbackLoopCategory = SubCategories[subCategoryValue as keyof typeof SubCategories];

  const { TRANSACTIONS, REPORTS } = RoutePaths;

  const redirectUrlBack = `${REPORTS}/${metaCategory.toLowerCase()}`;

  return (
    <Layout
      bgColor='light'
      hideContainer
      header={
        <LayoutHeader
          transparent
          title={t(CategoriesTranslation[metaCategory as keyof typeof CategoriesTranslation])}
          showDesktopNavigation
          hideRightButton
          button={<GoBackButton url={redirectUrlBack} />}
        />
      }
    >
      <Grid flexDirection='column' justifyContent='center' alignItems='center'>
        <GradientHeader>
          <TransactionDetails.Wrapper
            item
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            width='100%'
          >
            <TransactionDetails.Details color='common.white' variant='h5' sx={{ typography: { lg: 'h3' } }}>
              {formatCurrency(value, currencyCode as CurrencySymbol)}
            </TransactionDetails.Details>
            <MerchantWrapper
              date={formatFullDate(date, i18n.language)}
              merchant={merchant?.name || t('common:merchantPlaceholder')}
            />
          </TransactionDetails.Wrapper>
        </GradientHeader>
      </Grid>

      <TransactionDetails.PaperWrapper>
        <Container maxWidth={false} disableGutters>
          <TransactionDetails.PaperContainer>
            <TransactionDetails.Footprint>
              <HeaderWithValue unitVariant={!isLg ? 'h4' : 'h3'} value={footprint} color='common.black' />
            </TransactionDetails.Footprint>
            {(randomComparable as any)?.text && (
              <TransactionDetails.ComparableWrapper>
                <ComparableText text={(randomComparable as any)?.text} />
              </TransactionDetails.ComparableWrapper>
            )}
            <Typography
              textAlign='center'
              variant='subtitle3'
              sx={{ typography: { lg: 'body1' } }}
              color='common.black'
            >
              {t('transactionDetails:textHelper')}
            </Typography>
            {feedbackLoopCategory && (
              <NavLink redirectURL={`${TRANSACTIONS}/${id}/edit`} transaction={transaction}>
                <TransactionDetails.ButtonWrapper>
                  <Button text={t('transactionDetails:feedbackLoopButton')} />
                </TransactionDetails.ButtonWrapper>
              </NavLink>
            )}
          </TransactionDetails.PaperContainer>
          <EcoActionBanner />
          <TransactionDetails.InsightsWrapper>
            <TransactionDetails.InsightsContainer>{Insight}</TransactionDetails.InsightsContainer>
          </TransactionDetails.InsightsWrapper>
        </Container>
      </TransactionDetails.PaperWrapper>
    </Layout>
  );
};
