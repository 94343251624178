import { useEffect, useState } from 'react';
import {
  useQueryData,
  GetInsightByCategoryResponse,
  DEFAULT_OVERRIDE_CACHE_TIME,
  MetaCategoryType,
  useGetInsightByCategory,
} from '@ecolytiq/js-data-provider';

import { RandomInsightByCategory } from 'impactApp/modules/HomePage';
import { useDeps } from 'App/context';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { useTranslation } from 'react-i18next';

export const useRandomInsightsByCategoryData = (category: MetaCategoryType) => {
  const { httpClientService } = useDeps();
  const [response, setResponse] = useState<Omit<RandomInsightByCategory, 'isLoading'>>({
    randomInsightByCategory: {},
  });

  const { i18n } = useTranslation();

  const { randomInsightByCategory } = response;

  const navigate = useNavigate();

  const { SSO_PAGE } = RoutePaths;

  const { isLoading, isFetching, data, refetch } = useQueryData<{ data: GetInsightByCategoryResponse }>(
    useGetInsightByCategory(httpClientService, category),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data?.data) {
      setResponse({
        randomInsightByCategory: data.data.randomInsightByCategory,
      });
    } else if (!isLoading && !data) {
      navigate(SSO_PAGE);
    }
  }, [data, setResponse, navigate, SSO_PAGE, isLoading]);

  useEffect(() => {
    if (i18n.language) refetch();
  }, [refetch, i18n.language]);

  return {
    isLoading: isLoading || isFetching,
    randomInsightByCategory,
  };
};
