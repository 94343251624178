import { useState, useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from '@mui/material';
import { Button, ButtonAppearance } from 'components';

import { postAnswerOnboardingQuestions } from '@ecolytiq/js-data-provider';
import { handleFootPrintRefinement, handlePageRoute, handleSkipProfile } from 'App/utils';

import { color } from 'App/style';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { answerType } from 'impactApp/modules';

import { useDeps } from 'App/context';

import Slider from 'react-slick';

import { useCurrentAccountData } from '../../../impactApp/modules/FoodSettings/useCurrentAccountData';

import { Modal } from '../Modal';

import { Slide } from './Slide';

import { ProfileSettingsSliderStyles } from './ProfileSettingsSlider.styles';

type ProfileSettingsSlideProps = {
  closeHandler: () => void;
  submitButtonText: string;
};

export const ProfileSettingsSlider = ({ closeHandler, submitButtonText }: ProfileSettingsSlideProps) => {
  const [content, setContent] = useState([]);

  const [showButton, setShowButton] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const [currentAnswers, setCurrentAnswers] = useState<answerType[]>([]);

  const navigate = useNavigate();

  const { httpClientService } = useDeps();

  const { onboardingAnswers } = useCurrentAccountData();

  const { t, i18n }: any = useTranslation(['common']);

  const direction = i18n.dir(i18n.language);

  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    setContent(t(`transactionDetails:feedbackLoop1:questions`, { returnObjects: true }));
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      if (onboardingAnswers && onboardingAnswers.length > 0) {
        setCurrentAnswers(onboardingAnswers);
      } else {
        const emptyAnswers: answerType[] = [];

        content?.forEach((element: any) => {
          emptyAnswers.push({ questionId: element?.questionId, answerId: '', input: null });
        });
        setCurrentAnswers(emptyAnswers);
      }
    };

    fetchData();
  }, [content, onboardingAnswers]);

  const checkLastSlide = (currentSlide: number, nextSlide: number) => {
    if (nextSlide === content?.length - 1) setShowButton(true);
    else setShowButton(false);
  };

  const handleSubmit = () => {
    if (currentAnswers.length > 0) {
      const postAnswers = postAnswerOnboardingQuestions(
        httpClientService,
        currentAnswers
          .map(({ questionId, ...rest }) => {
            return rest;
          })
          .filter((answerObject) => Object.keys(answerObject).length > 0)
      );

      postAnswers.post();

      handleFootPrintRefinement(RoutePaths.PROFILE_SETTINGS, 'PROFILE');
    }
    navigate(RoutePaths.HOME);
  };

  const handleMatomoSlides = (currentSlide: number) => {
    handlePageRoute(`${RoutePaths.PROFILE_SETTINGS}/${currentSlide + 1}`);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: checkLastSlide,
    afterChange: handleMatomoSlides,
  };

  return (
    <Container disableGutters>
      <Modal
        slug='SKIPPROFILE'
        isOpen={isModalOpen}
        onCancel={() => {
          handleSkipProfile(RoutePaths.PROFILE_SETTINGS);
          closeHandler();
        }}
        onClose={() => setModalOpen(false)}
        content={t('common:closeProfileSettingsModal:content')}
        successButtonText={t('common:closeProfileSettingsModal:ja')}
        cancelButtonText={t('common:closeProfileSettingsModal:nein')}
      />
      <ProfileSettingsSliderStyles.Wrapper>
        <ProfileSettingsSliderStyles.CloseIcon hoverColor={color.highlightRed} onClick={() => setModalOpen(true)} />
        {content.length > 0 && (
          <ProfileSettingsSliderStyles.Slider rtlDirection={direction === 'rtl'} ref={sliderRef} {...settings}>
            {content?.map((element: any) => (
              <Slide
                key={`slide-${element?.title}`}
                question={element?.title}
                id={element?.questionId}
                answers={element?.answers}
                oldAnswers={currentAnswers}
                setAnswers={setCurrentAnswers}
                info={element?.info}
                questionType={element?.type}
              />
            ))}
          </ProfileSettingsSliderStyles.Slider>
        )}
        {showButton && (
          <ProfileSettingsSliderStyles.ButtonWrapper>
            <Button appearance={ButtonAppearance.LIGHT} iconRight text={submitButtonText} onClick={handleSubmit} />
          </ProfileSettingsSliderStyles.ButtonWrapper>
        )}
      </ProfileSettingsSliderStyles.Wrapper>
    </Container>
  );
};
